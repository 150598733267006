/* ****************
 * GENERATED CODE *
 **************** */
import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSync } from "../../system/HewSync";
import { BaseType } from "../../system/BaseType";

// Abstract
export class BaseOrganization extends BaseType {
	@HewSync.Field({ createOnly: true, generated: "util.autoId()", type: "ID" })
	public readonly organization: HewSync.ID<BaseOrganization>;

	@HewSync.Field({ type: "String", required: true })
	public readonly name: string;

	@HewSync.Field({ type: "String", default: '""' })
	public readonly description: string;
}
